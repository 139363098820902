
<template>
  <!--要旅行商城用户登录组件 -->
  <div id="user">
    <top :name="text" />
    <div class="sign">
      <p class="opder">请设置您的密码</p>
      <van-form @submit="onSubmit" style="margin-top: 0.4rem">
        <van-field
          :disabled="true"
          v-model="phone"
          name="phone"
          label="手机号码"
          placeholder="请填写手机号码"
          :rules="[{ required: false, message: '请填写手机号码' }]"
        />
        <van-field
          :disabled="false"
          v-model="word"
          type="digit"
          name="password"
          label="密码"
          placeholder="请设置10位数字以内的密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
        <!-- 组件穿插 协议内容 -->
        <van-field name="checkboxGroup" label="">
          <template #input>
            <van-checkbox-group v-model="checkboxGroup" direction="horizontal">
              <van-checkbox
                @click="
                  checkboxGroup[0] == 1 ? (is_click = true) : (is_click = false)
                "
                name="1"
                shape="square"
              ></van-checkbox>
              <span style="color: #2895c2"
                >我已阅读并同意《要旅行用户隐私通知》</span
              >
            </van-checkbox-group>
          </template>
        </van-field>
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >登录</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import top from "../components/filltop.vue";
export default {
  data() {
    return {
      text: "用户登录",
      phone: "",
      word: "",
      // checkbox: false,
      checkboxGroup: [],
      // false:false,
      is_click: false,
    };
  },
  components: {
    top,
  },
  methods: {
    isNumber(str) {
      // 正则判断是否包含英文字母
      var re = /[A-Za-z]+/;
      var resu = str.search(re);
      if (resu >= 0) {
        return false;
      } else {
        return true;
      }
    },
    onSubmit(values) {
      // console.log("submit", values);
      if (this.is_click == true) {
        console.log(this.word);
        // 判断密码位数以及类型
        if (this.isNumber(this.word)) {
          if (this.word.length > 10) {
            alert("请按要求填写10位数字内的密码");
          } else {
            //请求接口提交
            this.$https(
              "malldock/user",
              "user_url=" +
                JSON.parse(this.$route.params.success).user_image +
                "&phone=" +
                this.phone +
                "&type=" +
                1 +
                "&wxid=" +
                sessionStorage.getItem("wx_openid") +
                "&word=" +
                this.word,
              "post"
            ).then((res) => {
              // console.log(res.data)
              if (res.data.code == "000000") {
                // 添加成功 将手机号码和用户头像存入缓存
                this.$toast.fail(res.data.msg);
                sessionStorage.setItem("user_phone", res.data.data.phone);
                sessionStorage.setItem("user_image", res.data.data.user_img);
                sessionStorage.setItem("user_id", res.data.data.id);
                console.log(sessionStorage.getItem("user_id"))
                setTimeout(() => {
                  this.$router.go(-1);
                }, 1500);
              } else {
                this.$toast.fail(res.data.msg);
              }
            });
          }
        } else {
          alert("请按照要求填写密码格式");
        }
      } else {
        alert("请勾选并阅读协议");
      }
    },
  },
  mounted() {
    console.log(this.$route.params.success);
    this.phone = sessionStorage.getItem("user_phone");
  },
};
</script>


<style scoped>
#user {
  width: 100%;
  height: 100%;
  background: #f6f6f6;
  overflow: auto;
}
.sign {
  width: 94%;
  height: 8rem;
  background: white;
  border-radius: 0.2rem;
  position: absolute;
  top: 1.4rem;
  left: 0;
  right: 0;
  margin: auto;
}
.van-checkbox--horizontal >>> .van-checkbox__label {
  color: #2895c2;
}
.opder {
  font-size: 0.36rem;
  font-weight: bold;
  margin: 0.2rem auto;
  text-align: center;
  letter-spacing: 1px;
  color: #2283aa;
}
</style>