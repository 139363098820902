<template>
  <div style="">
    <div class="header-nav-float am-navbar am-navbar-dark">
      <div @click="togo(back)" style="margin-left: 0.2rem">
        <van-icon name="arrow-left" />
      </div>
      <div style="text-align: center">{{ name }}</div>
      <div style="margin-right: 0.2rem"></div>
    </div>
    <div class="zhezhao"></div>
  </div>
</template>

<script>
export default {
  props: ["name", "back"],
  methods: {
    togo(back) {
      if (back) {
        this.$router.go(-back);
      } else {
        this.$router.go(-1);
      }
    },
  },
  // beforeEach(){
  //     let toDepth = to.path.split('/').length
  //     let fromDepth = from.path.split('/').length
  //     if (toDepth < fromDepth) {
  //       // console.log('back...')
  //       from.meta.keepAlive = false
  //       to.meta.keepAlive = true
  // }
  //   }
};
</script>

<style scoped>
.zhezhao {
  width: 100%;
  height: 0.9rem;
}
.header-nav-float {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
}
.am-navbar,
.am-navbar-left,
.am-navbar-right,
.am-navbar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.am-navbar {
  -ms-flex-align: center;
  height: 1rem;
  justify-content: space-between;
  background-color: #008acb;
  color: #fff;
  font-size: 0.34rem;
}
.am-navbar div {
  flex: 1;
}
</style>